import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Calendly from "../components/Calendly"


import logoNewpricer from "../images/logo_podium.png"
import logoAtpco from "../images/references/ATPCO_partner.png"
import logoKlm from "../images/references/logo_KLM.png"
import logoDelta from "../images/references/Logo_Delta.png"
import logoAlitalia from "../images/references/logo_Alitalia.png"
import logoAirfrance from "../images/references/Logo_airfrance.png"
import logoMalaysia from "../images/references/Malaysia-Airlines-Logo.png"
import logoscenes from "../images/references/logo_scenes_nationales.jpeg"
import logoministere from "../images/references/Ministere_Culture.png"
import logoOM from "../images/references/OM.png"
import curve from "../images/Takeoffblue.jpg"
import wm from "../images/theatre.png"
import om from "../images/yield.jpg"
import ind from "../images/industry.png"
import win from "../images/win.svg"
import lemon from "../images/lemonade.svg"
import team from "../images/team.svg"
import perf from "../images/graphic.svg"
import fast from "../images/Fast.svg"
import target from "../images/Target.svg"
import info from "../images/Information.svg"
import process from "../images/Process.png"
import logoFrench from "../images/Logo_French_Tech.png"
import logoRegion from "../images/Logo-region.png"
import logoUnitec from "../images/unitec.png"
import concert from "../images/Concert.png"
import {Link} from "gatsby";


const IndexPage = () => (
  <Layout navbarScrollOpacity>
    <SEO title="Making Pricing Easy · Pricing Solutions" />


{/* Banniere d'entrée */}
    <div className="banner">
      <img src={curve} alt="revenue take-off" className="w-100"></img>
      <div className="container h-75">
        <div className="h-100 d-flex justify-content-right align-items-end ">
          <div className="row mb-1 pb-1 d-flex align-items-center children-mx-3">
            <img src={logoNewpricer} id="newpricer" alt="logo_newpricer" className="max-height-6rem ml-2 pl-5 ons"></img>
            <h3 className="h1 font-weight-lighter text-danger pl-4 ons">
              n e w p r i c e r
            </h3>
            <div className="ml-5 pl-5 us">
              <h3 className="h2 font-weight-lighter text-white ml-5 pl-5">
                pricing solutions
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>



    {/* Besoins */}
    <div className="row bg-light p-4">
      {/* Besoins/Entetes */}
      <div className="container">
        <h3 className="h1 text-dark py-3 text-center">
          Airlines struggle to be competitive
        </h3>
      </div>
    </div>

    <div className="row foot p-4">
      {/* Besoins/Entetes */}
      <div className="container">

        <h3 className="h3 font-weight-lighter text-center text-light p-2 m-2">
          Number of fares available are x2 every year!
        </h3>
        <h3 className="h3 font-weight-lighter text-center text-light p-2 m-2">
          Pricing teams are overwhelmed by tactical decisions instead of focusing on their real job: strategic pricing!
        </h3>
      </div>

      <div className="container text-light">
        {/* Besoins/images */}
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col text-center">
            <img className="w-50 mb-3" src={fast} alt="Fast working"></img>
          </div>
          <div className="col text-center">
            <img className="w-50 mb-3" src={info} alt="Good information"></img>
          </div>
          <div className="col text-center">
            <img className="w-50 mb-3" src={target} alt="Efficiency"></img>
          </div>
        </div>

        {/* Besoins/Titles */}
        <div className="row d-flex align-items-start justify-content-center">
          <div className="col">
            <div className="card-product-infos">
              <h6 className="text-center bolder">«We need to handle fare changes easily, quickly and reliably»</h6>
            </div>
          </div>
          <div className="col">
            <div className="card-product-infos">
              <h6 className="text-center bolder">«We need to be always competitive»</h6>
            </div>
          </div>
          <div className="col">
            <div className="card-product-infos">
              <h6 className="text-center bolder">«We need to maximize demand»</h6>
            </div>
          </div>
        </div>

        {/* Besoins/Texts */}
        <div className="row d-flex align-items-start justify-content-center">
          <div className="col">
            <div className="card-product-infos">
              <p className="text-justify">«Black Friday approaches. Our airline wants to launch 2 promos to all O&D with different segmentations for low and shoulder seasons.</p>
              <p>Forget your Excel sheet, with newpricer, it is done in few clics»</p>
            </div>
          </div>
          <div className="col">
            <div className="card-product-infos">
              <p className="text-justify">«It 5pm on X-mas eve, our main benchmark has just filed a new promo we must match asap.
                Newpricer triggers it and generates an action request. Pricing analyst only has to review and approve it.</p>
                <p>Filing is automatically proceeded into ATPCO.»</p>
            </div>
          </div>
          <div className="col">
            <div className="card-product-infos">
              <p className="text-justify">«End of April, bookings for May in business cabin are dramatically lagging. Pricing is requested to file asap a new business promo with a high risk of dilution!
              Thanks to newpricer, Peter knows that Business demand in May comes later than usual. Peter asks to wait just one more week… He was right: eventually, RASM in May broke record!»
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Process */}
    <div className="row bg-white p-5">
      <div className="container">
        <h3 className="h1 text-dark px-5 text-center">Fare management next generation</h3>

        <h3 className="h3 font-weight-lighter text-center text-dark p-2 m-2">
          Our fare management solution boosts speed, reliability and efficiency.
        </h3>
         {/* Process/ image */}
    <div className="row bg-light">
      <img className="w-100" src={process} alt="functionalities"></img>
    </div>
      </div>
    </div>


    {/* Impacts */}
    <div className="red py-3 text-white">
      <div className="container mb-5">
        <h3 className="h3 font-weight-lighter text-center text-white p-2 m-2">
            Impacts mesured on real cases:
        </h3>
        <div className="row d-flex align-items-center">
          <div className="col text-center">
            <div className="zoom">+30 %</div>
            <div>scope of competitive O&D</div>
          </div>
          <div className="col text-center">
            <div class="zoom">+15 %</div>
            <div>incremental demand</div>
          </div>
          <div className="col text-center">
            <div className="zoom">+50 %</div>
            <div>time saved for strategy</div>
          </div>
        </div>
      </div>
    </div>

    {/* Why us? */}
    <div className="row foot p-4">
      <div className="container">
        <h3 className="h1 text-light py-3">
          Why us?
        </h3>
        <h3 className="h3 font-weight-lighter text-center text-light p-2 m-2">
          Newpricer has been created by Pricing and Inventory managers from major airlines, like you.
        </h3>
        <h3 className="h3 font-weight-lighter text-center text-light p-2 m-2">
          We developed prototypes and algorithms to solve our own business issues. And yours as well...
        </h3>
      </div>
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col card-product m-3">
            <img className="h-50" src={win} alt="successful team"></img>
            <div className="p-1">
              <h2>Speaking from experience</h2>
              <p className="h1 text-justify">We have an extensive understanding RM and Pricing challenges. We develop prototypes and algorithms to solve your business issues.</p>
            </div>
          </div>
          <div className="col card-product m-3">
            <img className="h-50" src={team} alt="collaborative support"></img>
            <div className="p-1">
              <h2>We stand by you</h2>
              <p className="h1 text-justify">We offer a hybrid service including tools and business advices. Present and listening to your needs, we’ll implement with you cutting-edge pricing practices.</p>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col card-product m-3">
            <img className="h-50" src={lemon} alt="easy simple time saved"></img>
            <div className="p-1">
              <h2>Simple, intuitive, performant</h2>
              <p className="h1 text-justify">Newpricer is the selection of all best pricing practices gathered in one single tool easy to use.</p>
            </div>
          </div>
          <div className="col card-product m-3">
            <img className="h-50" src={perf} alt="efficiency IA ML"></img>
            <div className="p-1">
              <h2>Based on business rules and AI</h2>
              <p className="h1 text-justify">Our tools provide numerous KPIs and dashboards and suggest best adjustments based on IA engines.</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    {/* They trust us */}
    <div className="row p-4">
      <div className="container">
        <h3 className="h1 text-dark py-3">
          They trust us
        </h3>
      </div>
      <div className="container">
        <div className="row my-5 text-center">
          <div className="col p-3">
            <img className="logo" src={logoAirfrance} alt="AirFrance"></img>
          </div>
          <div className="col p-3">
            <img className="logo" src={logoAlitalia} alt="Alitalia"></img>
          </div>
          <div className="col p-3">
            <img className="logo" src={logoDelta} alt="Delta"></img>
          </div>
        </div>
        <div className="row my-5 text-center">
          <div className="col p-3">
            <img className="logo" src={logoAtpco} alt="ATPCO"></img>
          </div>
          <div className="col">
            <img className="logo1" src={logoscenes} alt="Scenes Nationales"></img>
          </div>
          <div className="col p-3">
            <img className="logo" src={logoKlm} alt="KLM"></img>
          </div>
        </div>
        <div className="row my-5 text-center">
          <div className="col">
            <img className="logo1" src={logoOM} alt="OM"></img>
          </div>

        </div>

      </div>
    </div>

  {/* Resquest a meeting
    <div className="red py-3 text-white">
      <div className="container">
        <div className="row d-flex align-items-center">
           <div id="schedule" className="col text-center">
              < Calendly />
           </div>
        </div>
      </div>
    </div> */}

    <div className="row foot p-5">
      <div className="container">
        <h3 className="row h1 text-light px-5">
            Other specific solutions
        </h3>
        <h3 className=" h3 font-weight-lighter text-center text-light p-5">
          We transpose our knowledge and experience to optimize your revenues
        </h3>
        <div className="row align-items-start justify-content-center  p-2">

          <div className="card-box mx-3">
            <div className="card-category culture py-3">
              T H E A T R E S
            </div>
            <div className="card-content px-1">
              <h5 className="pt-3">Meet your public</h5>
              <p className="text-center">Bookings analysis to increase to spectator satisfaction</p>
              <div className="icon"><img src={wm} alt="airline fare management"></img></div>
              <div className="w-100 text-secondary d-flex align-items-center justify-content-around py-3">
                <div className="text-center benefit">
                  <i className="fas fa-adjust"></i>
                  <p>control</p>
                </div>
                <div className="text-center benefit">
                  <i className="fas fa-tachometer-alt"></i>
                  <p>reactivity</p>
                </div>
                <div className="text-center benefit">
                  <i className="fas fa-people-arrows"></i>
                  <p>public</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card-box mx-3">
            <div className="card-category stadium py-3">
              S T A D I U M
            </div>
            <div className="card-content px-1">
              <h5 className="p-3">Fill the arena</h5>
              <p className="text-center">Real time yield management solution to maximize ticketing revenue at every match.</p>
              <div className="icon"><img src={om} alt="stadium yield management"></img></div>
              <div className="w-100 text-secondary d-flex align-items-center justify-content-around py-3">
                <div className="text-center benefit">
                   <i className="fas fa-chart-pie"></i>
                  <p>offers</p>
                </div>
                <div className="text-center benefit">
                   <i className="fas fa-users"></i>
                  <p>demand</p>
                </div>
                <div className="text-center benefit">
                  <i className="fas fa-chart-line"></i>
                  <p>profit</p>
                </div>
              </div>

              <a href="/teomenoFr/" className="learn btn btn-outline-danger font-weight-lighter">
                Learn more...
              </a>
            </div>
          </div>




          <div className="card-box mx-3">
            <div className="card-category commerce py-3">
              I N D U S T R Y
            </div>
            <div className="card-content px-1">
              <h5 className="p-3">Optimize margins</h5>
              <p className="text-center">Pricing solution monitoring competitors and raw material costs .</p>
              <div className="icon"><img src={ind} alt="stadium yield management"></img></div>
              <div className="w-100 text-secondary d-flex align-items-center justify-content-around py-3">
                <div className="text-center benefit">
                   <i className="fas fa-chart-pie"></i>
                  <p>offers</p>
                </div>
                <div className="text-center benefit">
                   <i className="fas fa-compass"></i>
                  <p>costs</p>
                </div>
                <div className="text-center benefit">
                  <i className="fas fa-chart-line"></i>
                  <p>profit</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


<div className="row bg-light p-5">
      <div className="container">
        <h3 className="h1 text-dark py-3">
            Our innovation partners
        </h3>
      </div>
      <div className="container">
        <div className="row my-5 text-center align-items-center">
          <div className="col">
            <img className="logo2" src={logoFrench} alt="FrenchTech"></img>
          </div>
          <div className="col ">
            <img className="logo2" src={logoUnitec} alt="Unitec"></img>
          </div>
          <div className="col ">
            <img className="logo" src={logoRegion} alt="NouvelleAquitaine"></img>
          </div>
          <div className="col ">
            <img className="logo" src={logoAtpco} alt="ATPCO"></img>
          </div>
          <div className="col ">
            <img className="logo1" src={logoministere} alt="Ministere de la Culture"></img>
          </div>
        </div>
      </div>
    </div>

    <div className="h-150vh red p-4 photo">
      <div className="container">
        <h3 className="h1 text-light py-3">
            Contact us
        </h3>
      </div>
      <div className="row">
        <div className="col us d-flex text-center justify-content-center align-items-center">
          <i className="col h1 fas fa-id-card text-light"></i>
        </div>
        <div className="col h4 font-weight-light text-light text-justify ons mx-5">
          <h1 className="h1 font-weight-lighter text-light ">
            Newpricer
          </h1>
          <p className="">
            2 Rue Doyen Georges Brus
          </p>
          <p className="">
            33600 Pessac
          </p>
          <p className="">
            France
          </p>
          <p className="">
           contact@newpricer.com
          </p>
        </div>
        {/*
        <div className="col-3 d-flex text-center justify-content-center align-items-center us">

            < Calendly />

         </div> */}
      </div>
    </div>

  </Layout>
)

export default IndexPage
